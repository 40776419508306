import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <header className="bg-black text-primary p-4">
      <div className="container mx-auto flex justify-between items-center">
        <Link to="/" className="text-2xl font-bold">AI Decision Roulette</Link>
        <nav>
          <Link to="/" className="mr-4 hover:text-opacity-80 transition-colors">Home</Link>
          <Link to="/privacy" className="hover:text-opacity-80 transition-colors">Privacy Policy</Link>
        </nav>
      </div>
    </header>
  );
};

export default Header;