import React from 'react';
import { Link } from 'react-router-dom';

const LandingPage = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-primary to-secondary flex items-center justify-center px-4 py-8">
      <div className="max-w-md w-full bg-black shadow-lg rounded-lg p-8">
        <h1 className="text-4xl font-bold text-center mb-4 text-primary">AI Decision Roulette</h1>
        <p className="text-white text-center mb-6">
          Let AI help you make decisions with a spin of the wheel!
        </p>
        <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4 mb-6">
          <a
            href="https://apps.apple.com/us/app/ai-decision-roulette"
            target="_blank"
            rel="noopener noreferrer"
            className="bg-white text-black px-4 py-2 rounded-lg flex items-center justify-center"
          >
            <span className="mr-2">App Store</span>
            <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24"><path d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8zm-1-13v6l5-3-5-3z"/></svg>
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.aidecisionroulette"
            target="_blank"
            rel="noopener noreferrer"
            className="bg-white text-black px-4 py-2 rounded-lg flex items-center justify-center"
          >
            <span className="mr-2">Google Play</span>
            <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24"><path d="M3 20.5v-17c0-.828.671-1.5 1.5-1.5.357 0 .687.126.939.336L18.5 12 5.439 21.664c-.252.21-.582.336-.939.336-.829 0-1.5-.672-1.5-1.5z"/></svg>
          </a>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;